<template>
  <div>
  <div v-if="messageList!=null && messageList.length>0" class="chat-container background-container">
    <div class="chat-messages" ref="chatMessages">
      <div v-if="messageList!=null">
        <div :class="{'hostaway-message': message.isIncoming, 'message-user': !message.isIncoming}"
             v-for="(message, index) in messageList" :key="index">
          <template v-if="!message.isIncoming">
            <div class="message-card-user">
              <span class="message-text">{{ removeHtmlTags(message.body) }}</span>
              <p class="message-date">{{ $moment(message.date).format('DD/MM/YYYY HH:mm:ss') }}</p>
            </div>
          </template>
          <template v-else>

            <div class="message-card-client">
              <span class="message-text">{{ removeHtmlTags(message.body) }}</span>
              <p class="message-date">{{ $moment(message.date).format('DD/MM/YYYY HH:mm:ss') }}</p>
            </div>
          </template>
          <!-- Display other message properties as needed -->
        </div>
      </div>
    </div>

  </div>
  <div v-else class="mt-0">
    <div class="text-center p-5">
      <div class="no-conversation">
        <i class="fas fa-stop mx-1 mt-4" style="color: #e75634"></i>{{ msg("No conversation yet") }}
      </div>
    </div>
  </div>
    <div class="chat-input mt-2" >
      <b-row>
        <b-col cols="10">
          <b-input :disabled="isInputDisabled" type="text" v-model="newMessage.body"
                   placeholder="Type a message..."></b-input>
        </b-col>
        <b-col cols="1">
          <b-button v-if="isTemplate" variant="primary" size="md" @click="sendMessage(true)">Template</b-button>
          <b-button v-else variant="primary" size="md" @click="sendMessage(false)">Send</b-button>
        </b-col>


      </b-row>
    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WhatsappChatComponent",
  props: ['clientId', 'reservationId'],
  watch: {
    clientId: {
      handler() {
        this.refresh();
      },
      immediate: true
    }, whatsapp: {
      handler() {
        this.refresh();
      },
      immediate: true
    }
  },
  data: () => ({
    loading: true,
    refreshIntervalId: null,
    ready: true,
    newMessage: {body: '', reservationId: ''},
    whatsappMessage: {},
    messageList: [],
  }),
  mounted() {
    this.startRefresh(); // Start the refresh interval when the component is mounted
  },
  beforeDestroy() {
    this.stopRefresh(); // Clear the interval when the component is destroyed
  },
  computed: {
    isInputDisabled() {
      return this.messageList == null || this.messageList.length < 1;
    },
    isTemplate() {
      if (this.messageList == null || this.messageList.length < 1) {
        return true; // Returns true if the list is null or empty
      }

      const firstItemDate = new Date(this.messageList[0].date)
      const twentyFourHoursAgo = new Date().getTime() - 24 * 60 * 60 * 1000;

      let boolean = firstItemDate.getTime() < twentyFourHoursAgo;
      return boolean;
    },

  },
  methods: {
    ...mapActions('whatsapp', ['getMessagesByClientId', 'sendWhatsappMessage']),
    removeHtmlTags(text) {
      const div = document.createElement('div');
      div.innerHTML = text;
      return div.textContent || div.innerText || ''; // Extracting text content without HTML tags
    },
    startRefresh() {
      // Execute refresh every 10 seconds (10000 milliseconds)
      this.refreshIntervalId = setInterval(() => {
        this.refresh();
      }, 10000);
    },


    stopRefresh() {
      // Clear the interval to stop refreshing
      clearInterval(this.refreshIntervalId);
    },

    getMessageClasses(message, isCrm = false) {
      return {
        'message-container': true,
        'hostaway-message': !isCrm && message.isIncoming,
        'crm-message': isCrm || message.source === 'crm'
      };
    },

    sendMessage(isTemplate) {
      if (isTemplate) {
        return this.sendTemplateMessage();
      }
      this.ready = false;
      const newMessage = {
        body: this.newMessage.body,
        clientId: this.clientId
      };
      this.sendWhatsappMessage({newMessage: newMessage, template: false}).then(this.onMessagesRetrievedAfterSend);
    },
    sendTemplateMessage() {
      this.ready = false;
      const newMessage = {
        body: 'Thank you for doing a Booking with Treasure Home !🙌🏠\n' +
            '\n' +
            'Can we start with the verification process ?\n' +
            '\n' +
            'If yes, please reply with a message saying „*Yes*“ .\n' +
            '\n' +
            '*Thank you*',
        clientId: this.clientId
      };
      this.sendWhatsappMessage({newMessage: newMessage, template: true}).then(this.onMessagesRetrievedAfterSend);
    },
    refresh() {
      if (this.clientId == null) {
        return;
      }
      this.getMessagesByClientId(this.clientId).then(this.onMessagesRetrieved);
    },
    onMessagesRetrieved(data) {
      this.whatsappMessage = data["messages"];
      if (this.whatsappMessage==null||this.whatsappMessage.result == null) {
        this.messageList = [];
        return;
      }
      this.messageList = this.whatsappMessage.result;
      this.messageList.reverse();
    },
    onMessagesRetrievedAfterSend(data) {
      this.whatsappMessage = data["messages"];
      if (this.whatsappMessage==null||this.whatsappMessage.result == null) {
        this.messageList = [];
        return;
      }
      this.messageList = this.whatsappMessage.result;
      this.messageList.reverse();
      this.newMessage = {body: '', reservationId: ''};
      this.ready = true;

    },

    formatDate(date) {
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Ensure 24-hour format
      };

      const formattedDate = new Date(date).toLocaleString('de-AT', options);
      return formattedDate.replace(',', ''); // Remove comma (optional)
    }

  }
}
</script>

<style scoped>
/* Style for the chat messages container */
.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

/* Style for the message cards */
.message-card-user {
  background-color: #075E54;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  color: white;
}

.message-card-client {
  background-color: #FEA47F;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  color: #000000;
}

/* Style for hostaway messages */
.hostaway-message {
  align-self: flex-start;
}

/* Style for user messages */
.message-user {
  align-self: flex-end;
}

.message-text {
  font-weight: bold;
  font-size: 14px;
}

.message-date {
  font-size: 9px;

}

.background-container {
  /* Set the container to cover the entire viewport */
  display: flex;
  flex-direction: column; /* Or use row if your content is horizontal */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-color: rgba(255, 255, 255, 0);
  /* Add other styles or adjust as needed */
}
.no-conversation {
  border: 2px dashed #e75634; /* Change color and thickness for the dashed border */
  padding: 0px 20px 20px;
  margin-top: 0px;
  display: inline-block; /* Ensures the border fits the content */
  border-radius: 10px; /* Optional: Add border-radius for rounded corners */
}
.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

/* Style for the message cards */
.message-card-user {
  background-color: #075E54;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  color: white;
  align-self: flex-end; /* Aligns outgoing messages to the right */
  max-width: 25%; /* Limit width of outgoing messages */
  margin-left: auto; /* Moves the content to the right end of the container */
}

.message-card-client {
  background-color: #FEA47F;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  color: #000000;
  align-self: flex-start; /* Aligns incoming messages to the left */
  max-width: 25%; /* Limit width of incoming messages */
}
</style>
