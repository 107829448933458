<template>
  <div>
    <!--parts modal-->
    <b-modal id="modal-contract-part" size="xl" v-model="modals.partsContract.active"
             centered hide-footer
             ref="modal" title="Review and sign contract"
             @show="()=>modals.partsContract.signature=null">
      <div class="contract-parts">
        <b-nav class="border-bottom" pills card-header slot="header" v-b-scrollspy:contract-part-nav-scroller>
          <b-nav-item v-bind:href="`#contract-part-${key}`" @click="scrollIntoView" v-for="(parts,key) in partsByTitle"
                      v-bind:key="key">{{ parts.title }}
          </b-nav-item>
        </b-nav>
        <div id="contract-part-nav-scroller" class="contract-part-nav-scroller px-3" ref="content">
          <div v-bind:id="`contract-part-${key}`" class="contract-part" v-for="(parts,key) in partsByTitle"
               v-bind:key="key">
            <b-row class="contract-part-paragraph" v-for="partData in parts.data" v-bind:key="partData.title">
              <b-col cols="1" class="border-right border-bottom">
                {{ partData.part.section }}.{{ partData.part.subSection }}
              </b-col>
              <b-col class="border-bottom border-right" cols="11">
                <div v-if="partData.part.editable !== true">
                  <div class="contract-part-actions" v-if="partData.part.type !== 'footer'">
                    <b-button variant="outline-info" size="sm"
                              @click="()=>addContractPart(partData.part,partData.index)"><i class="fas fa-plus"></i>
                    </b-button>
                    <b-button variant="outline-warning" size="sm" @click="()=>editContractPart(partData.index)"><i
                        class="fas fa-pen"></i></b-button>
                    <b-button variant="outline-danger" size="sm"
                              v-if="partData.part.type === 'title' || partData.part.type === 'paragraph'"
                              @click="()=>onRemoveContractPartRequest(partData.index)"><i class="fas fa-ban"></i>
                    </b-button>
                  </div>
                  <div v-html="partData.part.html" v-bind:key="partData.part.position"></div>
                </div>
                <div class="py-1" v-if="partData.part.editable === true">
                  <b-row class="pb-1">
                    <b-col>
                      <v-select v-bind:value="partData.part.type" @input="input=>updateTextType(partData.index, input)"
                                :options="textPartTypes" :clearable="false"
                                class="w-100" size="sm" label="label"
                                :reduce="(type) => type.value"
                                v-bind:disabled="partData.part.type !== 'title' && partData.part.type !== 'paragraph'"
                      />
                    </b-col>
                    <b-col v-if="partData.part.type === 'title'">
                      <b-form-input v-model="partData.part.text" placeholder="Title"/>
                    </b-col>
                    <b-col cols="2">
                      <b-button class="mx-1" variant="outline-warning"
                                v-if="partData.part.type === 'title' || partData.part.type === 'paragraph'"
                                @click="()=>onRemoveContractPartRequest(partData.index)">{{ msg("Remove") }}
                      </b-button>
                      <b-button variant="outline-dark" @click="()=>finalizeEdit(partData.index)">
                        {{ msg("close") }}
                      </b-button>
                    </b-col>
                  </b-row>
                  <div>
                    <quill-editor
                        v-model="partData.part.html"
                        @ready="quill=>onEditorReady(quill)"
                    ></quill-editor>
                  </div>
                  <div class="text-center">
                    <b-button size="sm" v-for="tag in textTags" v-bind:key="tag.value"
                              pill variant="outline-secondary" @click="()=>insertTag(partData.index, tag.value)">
                      {{ tag.label }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="text-center border-top pt-1">
          <b-button variant="outline-dark" class="mx-1" style="width: 120px"
                    @click="()=>modals.partsContract.active=false">{{ msg("Close") }}
          </b-button>
          <b-button variant="outline-success" style="width: 120px" @click="onSavePartsRequest">{{ msg("Save") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!--contract signature modal-->
    <b-modal id="modal-approve-contract" size="lg" v-model="modals.approveContract.active" centered hide-footer
             ref="modal" title="Review and sign contract"
             @show="()=>modals.approveContract.signature=null">
      <b-overlay :show="!modals.approveContract.loaded" rounded="sm" opacity="1" variant="white"
                 style="min-height: 500px;margin-top: -20px">
        <div>
          <iframe id="contract-data" ref="contract-data" class="w-100" style="height: 500px"></iframe>
        </div>
        <div class="position-relative" v-if="contract != null">
          <b-row no-gutters>
            <b-col cols="6">
              <div class="mt-2">
                <p><strong>Name:</strong><span class="mx-1">
                  {{ contract.landlord.firstname }} {{ contract.landlord.lastname }}
                </span></p>
                <p><strong>Date:</strong><span class="mx-1">{{ $moment().format("DD/MM/YYYY") }}</span></p>
              </div>
            </b-col>
            <b-col cols="6">
              <i class="fas fa-times float-right text-danger cursor-pointer" v-b-tooltip="`reset signature`"
                 @click="()=>$refs.signaturePad.clearSignature()"></i>
              <VueSignaturePad ref="signaturePad"
                               v-model="modals.approveContract.signature" height="100px"
                               class="mb-2 w-100" style="margin: 0 auto" :options="{}"/>
              <div class="position-absolute text-right"
                   style="top:70px;border-bottom: 2px solid #000;width: 100%;height: 0">
                <i class="fas fa-pen " style="position: absolute; top: -15px; right: 0;"></i>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="text-center">
          <b-button size="sm" variant="outline-secondary" class="mx-1"
                    @click="()=>modals.approveContract.active = false">
            <i class="fas fa-times"></i> Cancel
          </b-button>
          <b-button size="sm" variant="outline-success" class="mx-1" @click="onContractSigned()">
            <i class="fas fa-save"></i> I have read the contract and accepted
          </b-button>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal :id="infoModal.id" title="Handy-Signatur" cancel-title="Abbrechen" ok-title="Fortfahren" @ok="openWindow()"
             centered
             ok-variant="success" cancel-variant="warning" @hide="resetInfoModal">
      <h4 class="my-4">Sie werden nun zur Handy-Signatur Website weitergeleitet.</h4>
    </b-modal>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "contractGeneratorComponent",
  props: ['value'],
  data: () => ({
    sproofWindow: {
      window: null,
      open: false,
      link: null
    },
    infoModal: {
      id: 'info-modal',
      title: '',
      content: ''
    },
    modals: {

      approveContract: {
        active: false,
        signature: null,
        loaded: false,
      },
      partsContract: {
        active: false,
        textParts: {},
        editor: null,
        loaded: false,
      }
    }
  }),
  computed: {
    contract: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v)
      }
    },
    partsByTitle() {
      let title = {}
      let currentKey = null;
      let currentPosition = -1;
      let textParts = this.modals.partsContract.textParts;
      if (textParts == null || !Array.isArray(textParts.parts) || textParts.parts.length === 0)
        return title;
      textParts.parts.forEach((part, index) => {
        if (part.section - currentPosition >= 1) {
          currentPosition = part.section;
          currentKey = currentPosition;
          title[currentKey] = {data: [], title: `paragraph-${currentKey}`};
        }
        if (part.type === "header" || part.type === "title" || part.type === "footer") {
          title[currentKey].title = part.text;
        }
        title[currentKey].data.push({part, index})
      })
      return title;
    },
    textPartTypes() {
      return [
        {value: "paragraph", label: "Paragraph"},
        {value: "title", label: "Title"}
      ]
    },
    textTags() {
      return [
        {value: "VERMIETERDATEN", label: "VERMIETERDATEN"},
        {value: "MIETERDATEN", label: "MIETERDATEN"},
        {value: "ZIPANDCITY", label: "ZIPANDCITY"},
        {value: "ADDRESSANDHOUSE", label: "ADDRESSANDHOUSE"},
        {value: "APT", label: "APT"},
        {value: "SM", label: "SM"},
        {value: "EXTERNALSM", label: "EXTERNALSM"},
        {value: "STARTDATE", label: "STARTDATE"},
        {value: "ENDDATE", label: "ENDDATE"},
        {value: "IBAN", label: "IBAN"},
        {value: "LANDLORD", label: "LANDLORD"},
        {value: "TOTAL", label: "TOTAL"},
        {value: "BK", label: "BK"},
        {value: "TAX", label: "TAX"},
        {value: "KAUTION", label: "KAUTION"},
        {value: "MIETER", label: "MIETER"},
        {value: "CURRENTDATE", label: "CURRENTDATE"},
        {value: "MIETERVORNAME", label: "MIETERVORNAME"},
        {value: "MIETERNACHNAME", label: "MIETERNACHNAME"},
        {value: "MIETERBERUF", label: "MIETERBERUF"},
        {value: "MIETERBDAY", label: "MIETERBDAY"},
        {value: "MIETERTELEFON", label: "MIETERTELEFON"},
        {value: "MIETERANSCHRIFT", label: "MIETERANSCHRIFT"},
        {value: "MIETEREMAIL", label: "MIETEREMAIL"},
      ]
    }
  },
  methods: {
    ...mapActions("realEstateContracts", ["generateContractPdf", "sproofRequest", "generateAndSendLink", "signContract", "getContractParts", "saveContractParts"]),

    onContractRequest() {
      let $this = this;
      this.generateContractPdf(this.contract.id).then((data) => {
        console.log("generateContractPdf success")
        $this.$emit('contractSigned', data)

        $this.requestSignature()
      }, $this.onContractRequestFailed)
    },
    requestSignature() {
      console.log("requestSignature submit start")
      this.sproofRequest({contractId: this.contract.id}).then(data => {

            if (data['sproofResponse']['members'][0] != null) {
              this.sproofWindow.url = "https://sign.sproof.io/#/editor/" + data['sproofResponse']['members'][0]['id']
            }
            this.$root.$emit('bv::show::modal', this.infoModal.id)
          }
      )

    },
    openWindow() {
      this.sproofWindow.window = window.open(this.sproofWindow.url, '_blank', 'toolbar=no,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=400,height=800,left = 240,top = 212')

    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    onContractPartsRequest() {
      this.getContractParts(this.contract.id).then(this.onContractPartsReceived)
    },
    onContractPartsReceived(data) {
      let textParts = (data["parts"] || {});
      if (Array.isArray(textParts.parts)) {
        textParts.parts = textParts.parts.map(part => ({...part, editable: false}))
      }
      this.modals.partsContract.textParts = textParts;
      this.modals.partsContract.active = true;
    },
    addContractPart(part, index) {
      let newPart = {
        text: "", editable: true, html: "", params: [],
        section: part.section, subSection: part.subSection + 1, type: "paragraph"
      }
      for (let i = index + 1; i < this.modals.partsContract.textParts.parts.length; i++) {
        let contractPart = this.modals.partsContract.textParts.parts[i];
        if (contractPart.section !== part.section) {
          break;
        }
        contractPart.subSection += 1;
      }
      this.modals.partsContract.textParts.parts.splice(index + 1, 0, newPart)
    },
    editContractPart(index) {
      this.modals.partsContract.textParts.parts.forEach(part => part.editable = false);
      this.modals.partsContract.textParts.parts[index].editable = true;
    },
    onEditorReady(quill) {
      this.modals.partsContract.editor = quill;
    },
    insertTag(index, value) {
      const selection = this.modals.partsContract.editor.getSelection(); // get position of cursor (index of selection)
      this.modals.partsContract.editor.insertText(selection.index, `#${value}#`);
      let part = this.modals.partsContract.textParts.parts[index];
      if (part.params == null) {
        part.params = [];
      }
      if (part.params.indexOf(value) < 0) {
        part.params.push(value);
      }
    },
    updateTextType(index, type) {
      let part = this.modals.partsContract.textParts.parts[index];
      let currentType = part.type;
      part.type = type;
      if (currentType === type) {
        return;
      }
      if (currentType === 'title') {
        this.substractSection(part, index, 0)
      } else { // convert to title
        let offset = part.subSection;
        for (let i = index; i < this.modals.partsContract.textParts.parts.length; i++) {
          let contractPart = this.modals.partsContract.textParts.parts[i];
          contractPart.section += 1
          if (contractPart.section === part.section) {
            contractPart.subSection -= offset;
          }
        }
      }
    },
    onRemoveContractPartRequest(index) {
      let $this = this;
      this.$bvModal.msgBoxConfirm('Are you sure you want remove it ?', {
        title: 'Please Confirm',
        size: 'sm', buttonSize: 'sm', okVariant: 'danger', okTitle: 'YES', cancelTitle: 'NO', footerClass: 'p-0',
        hideHeaderClose: false, centered: true
      }).then(value => {
        if (value === true) {
          $this.removeContractPart(index);
        }
      })
    },
    removeContractPart(index) {
      console.log("removeContractPart")
      let part = this.modals.partsContract.textParts.parts[index];
      if (part.type === 'title') {
        this.substractSection(part, index, 1)
      } else {
        for (let i = index + 1; i < this.modals.partsContract.textParts.parts.length; i++) {
          let contractPart = this.modals.partsContract.textParts.parts[i];
          if (contractPart.section !== part.section) {
            break;
          }
          contractPart.subSection -= 1;
        }
      }
      this.modals.partsContract.textParts.parts.splice(index, 1);
    },
    substractSection(part, index, indexOffset) {
      let subSectionOffset = Math.max(...this.modals.partsContract.textParts.parts
          .filter(p => p.section === part.section - 1)
          .map(p => p.subSection)) + 1
      for (let i = index + indexOffset; i < this.modals.partsContract.textParts.parts.length; i++) {
        let contractPart = this.modals.partsContract.textParts.parts[i];
        if (contractPart.section === part.section) {
          contractPart.subSection = subSectionOffset++;
        }
        contractPart.section = contractPart.section - 1;
      }
    },
    finalizeEdit(index) {
      this.modals.partsContract.textParts.parts[index].editable = false;
    },
    onSavePartsRequest() {
      let $this = this;
      this.saveContractParts({id: this.contract.id, textParts: this.modals.partsContract.textParts})
          .then(data => {
            $this.onContractPartsReceived(data);
          })
    },

    onContractRequestFailed(errorData) {
      let $this = this;
      console.log("onContractRequestFailed", {error: errorData})
      let error = {errorType: "UNKNOWN", message: "Unknown error"}
      try {
        error = JSON.parse(new TextDecoder("utf-8").decode(errorData.data))
      } catch (e) {
        console.log(e)
      }
      let message = error.objects != null && error.objects.field != null ? error.objects.field + " is required" : error.message;
      this.$bvModal.msgBoxOk(message, {
        title: 'Error requesting contract',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      }).then(value => {
        $this.modals.approveContract.active = false;
      })

      console.log("onContractRequestFailed", error)
    },
    onContractSigned() {
      let $this = this;
      this.modals.approveContract.active = false
      let signature = $this.getSignature();
      this.signContract({contractId: this.contract.id, signature}).then(data => {
        $this.$emit('contractSigned', data)
        /* $this.contract = {...this.defaultContractObj(), ...data["contract"]};
         $this.permissions = data.permissions;
         $this.step = data.step;*/
        //$this.refresh();
      })
    },
    getSignature() {
      let signature = this.$refs.signaturePad.saveSignature();
      return signature.data
    },
    scrollIntoView(event) {
      event.preventDefault()
      const href = event.target.getAttribute('href')
      const el = href ? document.querySelector(href) : null
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop
      }
    }
  }
}
</script>

<style lang="scss">
.contract-parts {
  min-height: calc(100vh - 130px);

  .ql-editor {
    min-height: 300px;
  }

  .contract-part-nav-scroller {
    height: calc(100vh - 260px);
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .contract-part .contract-part-paragraph {
    .contract-part-actions {
      position: absolute;
      right: 5px;
      background-color: white;
      display: none;
    }

    &:hover {
      .contract-part-actions {
        display: block;
      }
    }
  }


}

</style>
