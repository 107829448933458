<template>
  <div>
    <b-row>

      <b-col cols="12" md="12" lg="7" style="float: right">
      </b-col>
      <b-col cols="12" md="6" lg="3" style="float: right">
        <v-select

            :dir="isRTL ? 'rtl' : 'ltr'"
            v-model="messageTemplateId"
            :options="allMessageTemplates"
            class="w-100"
            label="alias"
            :reduce="(branch) => branch.id"
            :clearable="false"
        />
      </b-col>
      <b-button :disabled="messageTemplateId==null" style="float: right" variant="primary"
                @click="sendTemplate">{{
          msg('Send template message')
        }}
      </b-button>


    </b-row>

    <b-card style="min-height: 450px" class="h-100">
      <b-card-title class="text-center  text-bold" style="font-size: 16px; font-weight: bold">
        <i class="fas fa-history" style="color: #f3a152"></i>

        {{ $t('History') }}

      </b-card-title>
      <b-table
          :items="history"
          :fields="fields"
          class="mt-5 custom-header"
          striped
          bordered
          outlined
      >

        <template #cell(timeToSend)="data">
          {{ formatDate(data.value) }}
        </template>

        <template #cell(sentTime)="data">
          {{ formatDate(data.value) }}
        </template>

        <template #cell(messageEvent)="data">
          <span style="font-weight: bold">{{ (data.value).replace(/_/g, ' ') }}</span>
        </template>

        <template #cell(success)="data">
          <b-badge :variant="(data.value !=null && data.value)?'success':'danger'">
            {{ (data.value != null && data.value) ? msg('yes') : msg('no') }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusVariant[data.value]">{{ $t(data.value) }}</b-badge>
        </template>

        <template #cell(id)="row">
          <div class="d-flex flex-column align-items-center gap-2 mb-1">
            <b-button size="sm" @click="openPreview(row.item, row.index, $event.target)"
                      class="btn btn-primary w-75" style="margin-bottom: 4px">
              {{ msg('Preview') }}
            </b-button>
            <b-button :disabled="row.item.status!=='NEW'" size="sm" @click="deleteHistory(row.item, row.index, $event.target)"
                      class="btn btn-danger w-75" style="margin-bottom: 4px">
              {{ msg('Delete') }}
            </b-button>
            <b-button size="sm" @click="sendHistory(row.item, row.index, $event.target)"
                      class="btn btn-warning w-75">
              {{ msg('Send now') }}
            </b-button>
          </div>
        </template>

      </b-table>
      <b-modal size="md" centered v-model="showModal.show" title="Preview" hide-footer>
        <div v-html="showModal.content"></div>
      </b-modal>
    </b-card>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "MessageTemplateHistoryComponent",
  props: {

    history: {
      type: Array,
      default: () => []
    },
    contractId: {
      type: String,
      required: true,
      default: null
    },
  },
  data() {
    return {
      messageTemplateId: null,
      allMessageTemplates: [],
      showModal: {
        id: 'show-modal',
        title: '',
        content: '',
        show: false,
      },
      statusVariant:
          {"NEW": "primary", "DELETED": "danger", "SENT_AUTOMATICALLY": "success", "SENT_MANUALLY": "success"},
      fields: [
        {key: 'status', label: this.$t('Status')},
        {key: 'messageEvent', label: this.$t('event')},
        {key: 'minutes', label: this.$t('minutes')},
        {key: 'messageChannel', label: this.$t('channel')},
        {key: 'timeToSend', label: this.$t('Time to send')},
        {key: 'sentTime', label: this.$t('Time sent')},
        {key: 'success', label: this.$t('success')},
        {key: 'id', label: this.$t('Actions')}
      ]
    };
  },
  created() {
    Promise.all([this.getAllMessageTemplates({amount: 9999}).then(ans => {
      this.allMessageTemplates = ans["MessageTemplates"];
    })])
        .finally(() => this.loaded = true)
  },
  methods: {
    ...mapActions("messageTemplate", ["sendMessageTemplate", "getAllMessageTemplates", "deleteMessageTemplate", "manuelSendMessageTemplate"]),
    sendTemplate() {
      let $this = this;
      console.log("im here 2")
      this.sendMessageTemplate({contractId: this.contractId, messageTemplateId: this.messageTemplateId})
          .then(ans => {
            $this.$toast.success($this.msg("Message sent"));
            $this.messageTemplateId = null;
          }, err => {
            $this.$toast.error($this.msg("Message not sent"));
          });

    },
    openPreview(item, index, button) {
      this.showModal.content = item.body;
      this.showModal.show = true
    },
    deleteHistory(item, index, button) {
      this.deleteMessageTemplate({ id: item.id })
          .then(ans => {
            if (this.history && this.history[index]) {
              this.$set(this.history, index, { ...item, ...ans });
            }
          })
          .catch(err => {
          });
    }

    ,
    sendHistory(item, index, button) {
      this.manuelSendMessageTemplate({id: item.id})
          .then(ans => {
            if (this.history && this.history[index]) {
              this.$set(this.history, index, { ...item, ...ans });
            }

          })
          .catch(err => {
          });
    }
    ,
    formatDate(date) {
      if (!date) return '';
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Ensure 24-hour format
      };

      const formattedDate = new Date(date).toLocaleString('de-AT', options);
      return formattedDate.replace(',', ''); // Remove comma (optional)
    }
    ,
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
  }
};
</script>
<style lang="scss" scoped>
.b-table th {
  background-color: #007bff !important; // Bootstrap primary color
  color: white;
}
</style>
