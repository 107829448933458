<template>
  <b-card>
    <b-card-header class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <i class="fa-solid fa-house-chimney-crack" style="color: #FEA47F;font-size: 19px"/>
        <h4 class="mb-1 ml-50">{{msg("Damages")}}</h4>
      </div>
      <div v-if="damages">
        <b-badge v-if="damages.paid !== true" variant="danger" style="margin-right: 5px">{{msg("Not payed")}}</b-badge>
        <b-badge v-else variant="success" style="margin-right: 5px">{{msg("Not payed")}}</b-badge>

        <b-badge v-if="damages.damageFixed !== true" variant="danger" style="margin-right: 5px">{{msg("Not fixed")}}</b-badge>
        <b-badge v-else variant="success" style="margin-right: 5px" >{{msg("Not fixed")}}</b-badge>
      </div>
    </b-card-header>
    <div v-if="damages">
      <p><b>{{msg('Description')}}:</b> {{damages.damageDescription}}</p>
      <b-container fluid class="p-0">
        <b-row>
          <b-col v-for="img in damages.images" v-bind:key="img">
            <b-img style="max-height: 150px" thumbnail fluid :src="getUrl(`damage/image/${damages.id}/${img}`)" alt="Image 1"></b-img>
          </b-col>
        </b-row>
      </b-container>
      <div class="text-right">
        <b-button size="sm" variant="primary" @click="refresh"  v-if="damages.paid !== true">{{msg("Charge")}}</b-button>
      </div>
    </div>
  </b-card>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: "ContractDamageComponent",
  props: ['contractId'],
  watch:{
    contractId:{
      handler(){
        this.damages = null;
        this.refresh();
      },
      immediate: true
    }
  },
  data:()=>({
    damages: null,
  }),
  methods:{
    ...mapActions('damage',["getContractDamage"]),
    refresh(){
      this.getContractDamage(this.contractId).then(ans => {
        this.damages = ans["Damages"];
      }, e=>{});
    }
  }
}
</script>



<style scoped>

</style>
