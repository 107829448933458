<template>
  <b-card style="min-height: 450px" class="h-100">
    <b-card-title class="text-center mt-1 text-bold" style="font-size: 16px; font-weight: bold">
      <i class="far fa-credit-card mx-1" style="color: #f3a152"></i>

      {{ $t('payments') }}

    </b-card-title>
    <b-table
      :items="payments"
      :fields="fields"
      class="mt-5 custom-header"
      striped
      bordered
      outlined
    >

      <template #cell(paymentDate)="data">
        {{ formatDate(data.value) }}
      </template>

      <template #cell(amount)="data">
        {{ convertToEURO(data.value) }}
      </template>

    <template #cell(brand)="data">
        {{ (data.value !=null)?data.value:'' }} {{' **** '+data.item.digits}}
      </template>

    </b-table>
  </b-card>
</template>

<script>
export default {
  name: 'PaymentClientTable',
  props: {
    title: {
      type: String,
      default: 'Payments'
    },
    payments: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      fields: [
        {key: 'reason', label: 'Reason'},
        {key: 'amount', label: 'Amount'},
        {key: 'brand', label: 'Card'},
        {key: 'paymentDate', label: 'Date'}
      ]
    };
  },
  methods: {
    formatDate(date) {
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Ensure 24-hour format
      };

      const formattedDate = new Date(date).toLocaleString('de-AT', options);
      return formattedDate.replace(',', ''); // Remove comma (optional)
    }
    ,
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
  }
};
</script>
<style lang="scss" scoped>
.b-table  th {
  background-color: #007bff !important; // Bootstrap primary color
  color: white;
}
</style>
