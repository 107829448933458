<template>
  <div class="py-1">
    <div class="text-right mb-1">
      <b-button size="sm" variant="outline-secondary" class="mx-1" @click="refresh">{{msg("Refresh")}}</b-button>
      <b-button size="sm" variant="outline-success" @click="onNewAlertRequest">{{msg("New")}}</b-button>
    </div>
    <!--Table-->
    <b-table ref="contracts-table" striped hover responsive
             thead-class="text-center" tbody-tr-class="text-center"
             v-bind:fields="fields" v-bind:items="alerts" :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mx-1"></b-spinner>
          <strong>{{ msg('Loading') }}...</strong>
        </div>
      </template>
      <template #cell(severity)="data">
        <div v-bind:class="{
            'text-info':data.value==='READ_ONLY',
            'text-danger':data.value==='CRITICAL',
            'text-warning':data.value==='WARNING',
          }">
          <feather-icon icon="AlertCircleIcon" size="18"/>
          {{ data.value }}
        </div>
      </template>
      <template #cell(code)="data">
        <div class="text-center">
          <div v-if="(data.value || '').toUpperCase()==='PAYMENT'">
            <feather-icon icon="CreditCardIcon" size="18"/>
          </div>
          <div v-if="(data.value || '').toUpperCase()==='FILES'">
            <feather-icon icon="FileIcon" size="18"/>
          </div>
          <div v-if="(data.value || '').toUpperCase()==='CONTRACT'">
            <feather-icon icon="FileTextIcon" size="18"/>
          </div>
          <div v-if="(data.value || '').toUpperCase()==='GENERAL'">
            <feather-icon icon="PlusCircleIcon" size="18"/>
          </div>
          {{ data.value }}
        </div>
      </template>
      <template #cell(actions)="data">
        <div class="text-center">
          <b-link class="text-secondary mr-1" @click="()=>onAlertEditRequest(data.item.id)">
            <feather-icon icon="EditIcon" size="18"/>
          </b-link>
          <b-link class="text-danger" @click="()=>onAlertDeleteRequest(data.item.id)">
            <feather-icon icon="TrashIcon" size="18"/>
          </b-link>
        </div>
      </template>
      <template #cell(lockClientSystem)="data">
        <div class="text-center">
          <i class="fas fa-times text-danger" v-if="data.value === true"></i>
        </div>
      </template>
      <template #cell(visible)="data">
        <div class="text-center">
          <i class="fas fa-user" style="font-size: 16px; padding: 1px" v-if="data.item.showToClient" v-b-tooltip="msg('client')"></i>
          <i class="fas fa-user-tie" style="font-size: 16px; padding: 1px" v-if="data.item.showToOwner" v-b-tooltip="msg('owner')"></i>
        </div>
      </template>
    </b-table>
    <b-modal v-model="modal.active" size="lg" centered hide-footer>
      <b-table-simple v-if="modal.alert != null" responsive>
        <b-tr v-if="modal.alert.id != null">
          <b-td cols="6">{{msg("id")}}</b-td>
          <b-td cols="6">{{modal.alert.id}}</b-td>
        </b-tr>
        <b-tr v-if="modal.alert.id != null">
          <b-td cols="6">{{msg("creationDate")}}</b-td>
          <b-td cols="6">{{modal.alert.creationDate}}</b-td>
        </b-tr>
        <b-tr>
          <b-td cols="6">{{msg("Severity")}}</b-td>
          <b-td cols="6">
            <v-select class="select-size-sm"
                      v-model="modal.alert.severity" :dir="isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"  label="name" :options="severities" :reduce="severity=>severity.name"
            />
          </b-td>
        </b-tr>
        <b-tr>
          <b-td cols="6">{{msg("Code")}}</b-td>
          <b-td cols="6">
            <v-select class="select-size-sm"
                      v-model="modal.alert.code" :dir="isRTL ? 'rtl' : 'ltr'"
                      :clearable="false" label="name" :options="alertCodes"
                      :reduce="alertCode=>alertCode.name"
            />
          </b-td>
        </b-tr>
<!--        <b-tr>
          <b-td cols="6">{{msg("actions")}}</b-td>
          <b-td cols="6">
            <v-select class="select-size-sm"
                      v-model="modal.alert.actionRequired" :dir="isRTL ? 'rtl' : 'ltr'"
                      label="name" :options="actions"
                      :reduce="action=>action.name"
            />
          </b-td>
        </b-tr>-->
        <b-tr>
          <b-td cols="6">{{msg("Message")}}</b-td>
          <b-td cols="6">
            <b-textarea v-model="modal.alert.message"
                        v-bind:disabled="modal.alert.genericMessage"/>
            <span class="text-danger m-0" v-if="modal.alert.genericMessage">{{msg("This alert message cannot be edited")}}</span>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td cols="6">{{msg("User message")}}</b-td>
          <b-td cols="6">
            <b-textarea v-model="modal.alert.userMessage"/>
          </b-td>
      </b-tr>
        <b-tr>
          <b-td cols="6">{{msg("Lock Client System")}}</b-td>
          <b-td cols="6">
            <b-checkbox button-variant="danger" v-model="modal.alert.lockClientSystem"/>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td cols="6">{{msg("Show alert")}}</b-td>
          <b-td cols="6">
            <b-form-checkbox v-model="modal.alert.showToClient" inline>{{msg("Client")}}</b-form-checkbox>
            <b-form-checkbox v-model="modal.alert.showToOwner" inline>{{msg("Owner")}}</b-form-checkbox>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="text-left" >
            <b-button variant="warning">{{ msg("Reset") }}</b-button>
          </b-td>
          <b-td class="text-right" >
            <b-button variant="success" @click="onModalRequestEdit">{{ msg("Update") }}</b-button>
          </b-td>
        </b-tr>
      </b-table-simple>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ContractAlertComponent",
  props: ['contractId'],
  watch: {
    contractId: {
      handler() {
        this.refresh();
      },
      immediate: true
    }
  },
  data: () => ({
    loading: true,
    alerts: [],
    modal:{
      active:false,
      alert:null,

    }
  }),
  computed: {
    ...mapGetters('realEstatesContractsAlerts', ['alertCodes', 'severities', 'actions']),
    fields() {
      let $this = this
      return [
        {
          key: 'creationDate',
          label: $this.msg('Creation Time'),
          sortable: true
        },
        {
          key: 'severity',
          label: $this.msg('severity'),
          sortable: true
        },
        {
          key: 'code',
          label: $this.msg('code'),
          sortable: true
        },
        {
          key: 'message',
          label: $this.msg('message'),
          sortable: true
        },
        {
          key: 'userMessage',
          label: $this.msg('userMessage'),
          sortable: true
        },
        {
          key: 'lockClientSystem',
          label: $this.msg('lockClientSystem'),
          sortable: true
        },
        {
          key: 'visible',
          label: $this.msg('visible'),
          sortable: true
        },
        {
          key: 'actions',
          label: $this.msg('actions'),
          sortable: true
        },
      ]
    }
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions('realEstatesContractsAlerts', ['getData', 'getAllContractAlerts', 'addContractAlert', 'editContractAlert', 'removeContractAlert']),
    refresh() {
      if (this.contractId == null) {
        return;
      }
      this.loading = true;
      this.getAllContractAlerts(this.contractId).then(this.onAlertsRetrieved);
    },
    onAlertsRetrieved(data) {
      this.alerts = data["alerts"];
      this.loading=false;
    },
    onAlertRetrieved(alert) {
      let index =  this.alerts.findIndex(a=>a.id===alert.id);
      if(index >= 0) {
        this.alerts[index] = alert;
        this.alerts = [...this.alerts];
      }else {
        this.alerts.push(alert);
      }
      this.modal.active = false;
    },
    onNewAlertRequest(){
      this.modal.alert = {
          "showToClient": true, "showToOwner": false, "lockClientSystem": false, "code": "GENERAL",
          "message": "", "userMessage": null, "genericMessage": false,
          "severity": "", "actionRequired": null };
      this.modal.active = true;
    },
    onAlertEditRequest(alertId) {
      let alert = this.alerts.find(alert => alert.id === alertId)
      this.modal.alert = {...alert};
      this.modal.active = true;
    },
    onModalRequestEdit(){
      let $this = this;
      if(this.modal.alert.id != null){
        this.editContractAlert({id:this.modal.alert.id,alert: this.modal.alert})
            .then(this.onAlertRetrieved)
      }else {
        this.addContractAlert({contractId: this.contractId, alert: this.modal.alert})
            .then(this.onAlertRetrieved)
      }
    },
    onAlertDeleteRequest(alertId) {
      let $this = this;
      this.$bvModal.msgBoxConfirm('Are you sure you want delete this alert?', {
        title: 'Please Confirm',
        size: 'sm', buttonSize: 'sm',
        okVariant: 'danger', okTitle: 'YES',
        cancelTitle: 'NO',
        hideHeaderClose: false, centered: true
      }).then(value => {
        if (value === true) {
          $this.removeContractAlert(alertId).then($this.onAlertsRetrieved);
        }
      })

    }
  }
}
</script>

<style scoped>

</style>
